import '../styles/main.scss';
import * as bootstrap from 'bootstrap';

//import 'datatables.net';
//import 'jquery-datatables-checkboxes';
//import pt_BR from './inc/datatables-pt-br.js';
import { data } from 'jquery';

//import 'jquery-mask-plugin';

/** Fontawesome */
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
//import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(
  fas,
  //far,
  fab
);
dom.watch();


import ScrollReveal from 'scrollreveal';


const pg = document.querySelector("meta[property='site:base_url']").getAttribute("content");

if (window.history.replaceState) window.history.replaceState(null, null, window.location.href);


/*document.addEventListener('DOMContentLoaded', function()
{*/
  /*
   * Open modals.
   */
  const modalTriggerList = document.querySelectorAll('[data-modal]');
  modalTriggerList.forEach(modalTrigger =>
  {
    let modalId = modalTrigger.getAttribute('id');
    let modalElement = document.getElementById(modalId);

    if (modalElement != null)
    {
      modalElement = new bootstrap.Modal(modalElement);
      modalElement.show();
    }
  });

  /*
   * Open Toasts.
   */
  const toastTriggerList = document.querySelectorAll('[data-toast]');
  toastTriggerList.forEach(toastTrigger =>
  {
    let toastId = toastTrigger.getAttribute('id');
    let toastElement = document.getElementById(toastId);

    if (toastElement != null)
    {
      let toast = new bootstrap.Toast(toastElement);
      toast.show();
    }
  });

  /*
   * Open Tooltip.
   */
  const tooltipTriggerList = document.querySelectorAll('[title]');
  tooltipTriggerList.forEach(tooltipTrigger => {
    new bootstrap.Tooltip(tooltipTrigger);
  });
//});

document.addEventListener('DOMContentLoaded', function()
{
  window.sr = ScrollReveal({reset: false});

  function revealAnimation(selector, options)
  {
      const elements = document.querySelectorAll(selector);
    if (elements.length > 0) sr.reveal(selector, options);
  }

  revealAnimation('.animate-top', {
      duration: 1000,
      origin: 'top',
      distance: '0.625'
  });

  revealAnimation('.animate-left', {
      duration: 1000,
      origin: 'left',
      distance: '0.625'
  });

  revealAnimation('.animate-center', {
      duration: 1000,
      origin: 'center',
      distance: '0.625'
  });

  revealAnimation('.animate-right', {
      duration: 1000,
      origin: 'right',
      distance: '0.625'
  });

  revealAnimation('.animate-bottom', {
      duration: 1000,
      origin: 'bottom',
      distance: '0.625'
  });

  revealAnimation('.animate-reverse-top', {
      duration: 1000,
      origin: 'top',
      distance: '0.625',
      reset: true
  });

  revealAnimation('.animate-reverse-left', {
      duration: 1000,
      origin: 'left',
      distance: '0.625',
      reset: true
  });

  revealAnimation('.animate-reverse-center', {
      duration: 1000,
      origin: 'center',
      distance: '0.625',
      reset: true
  });

  revealAnimation('.animate-reverse-right', {
      duration: 1000,
      origin: 'right',
      distance: '0.625',
      reset: true
  });

  revealAnimation('.animate-reverse-bottom', {
      duration: 1000,
      origin: 'bottom',
      distance: '0.625',
      reset: true
  });
});


// scroll
var scrollWindow = function()
{
  $(window).scroll(function()
  {
    var $w = $(this),
        st = $w.scrollTop(),
        navbar = $('.ftco_navbar'),
        sd = $('.js-scroll-wrap');

    if (st > 150) {
      if ( !navbar.hasClass('scrolled') ) {
        navbar.addClass('scrolled');
      }
    }

    if (st < 150) {
      if ( navbar.hasClass('scrolled') ) {
        navbar.removeClass('scrolled sleep');
      }
    }

    if ( st > 350 ) {
      if ( !navbar.hasClass('awake') ) {
        navbar.addClass('awake');
      }

      if(sd.length > 0) {
        sd.addClass('sleep');
      }
    }

    if ( st < 350 ) {
      if ( navbar.hasClass('awake') ) {
        navbar.removeClass('awake');
        navbar.addClass('sleep');
      }
      if(sd.length > 0) {
        sd.removeClass('sleep');
      }
    }

  });
};
scrollWindow();


function fullHeightPage()
{
  var elements = document.querySelectorAll('[data-fullheightPage]');

  function set_height()
  {
    elements.forEach(function(element) {
      element.style.height = window.innerHeight + 'px';
    });
  }

  set_height();

  window.addEventListener('resize', function() {
    set_height();
  });
}
window.onload = fullHeightPage();




function escapeSelector(value)
{
  return value.replace(/([!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~])/g, "\\$1");
}

function previewImagem(input_id)
{
  var selector = 'input[id="' + escapeSelector(input_id) + '"]';
  var imagem   = document.querySelector(selector).files[0];
  var preview  = document.querySelector("#preview-image-" + escapeSelector(input_id));

  var reader = new FileReader();
  reader.onloadend = function () {
    preview.src = reader.result;
  }

  if (imagem) {
    reader.readAsDataURL(imagem);
  } else {
    preview.src = "";
  }
}


function show_password(input_id)
{
  var tipo = document.getElementById(input_id);
  if (tipo.type == "password") tipo.type = "text";
  else tipo.type = "password";
}

/*
 * Cookies LGPD system.
 */
(function ()
{
  "use strict";

  var cookieAlert = document.querySelector(".cookiealert");
  var acceptCookies = document.querySelector(".acceptcookies");

  if (!cookieAlert) return;

  cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

  // Show the alert if we cant find the "acceptCookies" cookie
  if (!getCookie("acceptCookies")) cookieAlert.classList.add("show");

  // When clicking on the agree button, create a 1 year
  // cookie to remember user's choice and close the banner
  acceptCookies.addEventListener("click", function ()
  {
    setCookie("acceptCookies", true, 365);
    cookieAlert.classList.remove("show");

    // dispatch the accept event
    window.dispatchEvent(new Event("cookieAlertAccept"))
  });

  // Cookie functions from w3schools
  function setCookie(cname, cvalue, exdays)
  {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname)
  {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return "";
  }

  if (!getCookie("acceptCookies"))
  {
    $(document).ready(function() {
      $('.cookiealert').modal('show');
    });
  }
})();


/*
 * Cookies LGPD system.
 */
$(() =>
{

  /*$(document).ready(function(){

      $('[data-mask-money]').maskMoney({
          //prefix: 'R$ ',
          allowNegative: false,
          thousands: '.',
          decimal: ',',
          affixesStay: false
      });

      $('#zipcode').blur(function(){
          buscarCep();
      });
  });

  function buscarCep() {
      var cep = $('#zipcode').val().replace(/\D/g, '');
      if (cep.length != 8) {
          alert('CEP inválido');
          return;
      }
      $.getJSON('https://viacep.com.br/ws/' + cep + '/json/', function(data) {
          if (!data.erro) {

              $('#street').val(data.logradouro);
              $('#district').val(data.bairro);
              $('#city').val(data.localidade);
              $('#state').val(data.uf);
          } else {
              alert('CEP não encontrado');
          }
      });
  }*/

  //Apresentar ou ocultar o menu
  $('.sidebar-toggle').on('click', function () {
    $('.sidebar').toggleClass('toggled');
  });

  //carregar aberto o submenu
  var active = $('.sidebar .active');
  if (active.length && active.parent('.collapse').length) {
    var parent = active.parent('.collapse');
    parent.prev('a').attr('aria-expanded', true);
    parent.addClass('show');
  }

  //Carregar modal define para apagar
  $('a[data-delete]').click(function(ev){
    var href = $(this).attr('href');

    if( !$('#confirm-delete').length ) {
      $('body').append(`
      <div class="modal fade" id="confirm-delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <form class="modal-content" method="POST" data-delete-return action="`+href+`">
            <div class="modal-header bg-danger text-white">
              EXCLUIR REGISTRO
              <button type="button" class="close" data-dismiss="modal" title="Fechar" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
              Tem certeza de que deseja EXCLUIR o registro selecionado?
              <div class="row-form" id="tables-to-action"></div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal">Cancelar</button>
              <button type="submit" name="delete-register" class="btn btn-danger text-white">Apagar</button>
            </div>
          </form>
        </div>
      </div>`);

      var href = href.split('&foreign_key=');
      href = href[1].split('&');
      var foreign_key = href[0];

      $.ajax({
        method: "GET",
        url: pg + '/rest-api/show-tables',
        data: {
          "foreign_key": foreign_key,
        },
        success: function(response){
          $('#tables-to-action').append(response.input);
        }
      });
    }

    $('#confirm-delete').modal({show: true});

    var modal = $('#confirm-delete');
    modal.modal({ show: true });

    modal.on('hidden.bs.modal', function () {
      modal.remove();
    });

    return false;
  });

  // Carregar modal define para duplicar
  $('a[data-duplicate]').click(function(ev){
    var href = $(this).attr('href');

    if(!$('#confirm-duplicate').length) {
      $('body').append(`
      <div class="modal fade" id="confirm-duplicate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <form class="modal-content" method="POST" data-duplicate-return action="`+href+`">
            <div class="modal-header bg-info text-white">
              DUPLICAR REGISTRO
              <button type="button" class="close" data-dismiss="modal" title="Fechar" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
              Tem certeza de que deseja DUPLICAR o registro selecionado?
              <div class="row-form" id="tables-to-action-duplicate"></div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal">Cancelar</button>
              <button type="submit" name="duplicate-register" class="btn btn-info text-white">Duplicar</button>
            </div>
          </form>
        </div>
      </div>`);

      var href = href.split('&foreign_key=');
      href = href[1].split('&');
      var foreign_key = href[0];

      $.ajax({
        method: "GET",
        url: pg + '/rest-api/show-tables',
        data: {
          "foreign_key": foreign_key,
        },
        success: function(response){
          $('#tables-to-action-duplicate').append(response.input);
        }
      });
    }

    var modal = $('#confirm-duplicate');
    modal.modal({ show: true });

    modal.on('hidden.bs.modal', function () {
      modal.remove();
    });

    return false;
  });

  //Carregar modal define para TRUNCAR tabela
  $('a[data-truncate]').click(function(ev){
    var href = $(this).attr('href');

    if(!$('#confirm-truncate').length){
      $('body').append(`
      <div class="modal fade" id="confirm-truncate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header bg-danger text-white">
              LIMPAR TODOS OS REGISTROS
              <button type="button" class="close" data-dismiss="modal" title="Fechar" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
              Tem certeza de que deseja LIMPAR todos os registros?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-dismiss="modal">Cancelar</button>
              <a class="btn btn-danger text-white" id="truncate-table">LIMPAR REGISTROS</a>
            </div>
          </div>
        </div>
      </div>`);
    }

    $('#truncate-table').attr('href', href);
    $('#confirm-truncate').modal({show: true});

    return false;
  });


  // Anchor
  $(document).on('click', 'a[href^="#"]', function (e)
  {
    e.stopPropagation();
    e.preventDefault();

    var link = $(this);
    var anchor = link.attr("href");

    if ( (anchor == "#") || ($(anchor).length == 0) ) return false;

    var headerHeight = $("nav").height();
    var position = $(anchor).offset().top - headerHeight - 32;

    $("html, body, .app").scrollTop(position);
  });

});

$(document).on('submit', '[data-delete-return], [data-duplicate-return]', function (e)
{
  e.preventDefault();

  $('.modal').modal('hide');

  // Form
  var form = $(this);
  var form_action = form.attr('action');
  var form_method = form.attr('method');
  var formData = new FormData(form[0]);

  $.ajax({
    url: form_action,
    type: form_method,
    data: formData,
    processData: false,
    contentType: false,
    success: function (response)
    {
      console.log(response);

      $('body').append(response.msg);
      var toast = $('.toast');
      toast.toast('show');

      toast.on('hidden.bs.toast', function () {
        toast.remove();
      });

      setInterval(function() {
        location.reload();
      }, 1500);
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log(jqXHR);
      console.log(textStatus);
      console.log(errorThrown);
    }
  });
});


var getUrlParameter = function getUrlParameter(sParam)
{
  var sPageURL = window.location.search.substring(1),
  sURLVariables = sPageURL.split('&'),
  sParameterName,
  i;

  for (i = 0; i < sURLVariables.length; i++)
  {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
  }
};


/*jQuery(function($)
{
  $(".mask-rg").mask("99.999.999-9");
  $(".mask-cpf").mask("999.999.999-99");
  $(".mask-cnpj").mask("99.999.999/9999-99");
  $(".mask-cep").mask("99999-999");
  $(".mask-cellphone").mask("(99) 99999-9999");
  $(".mask-phone").mask("(99) 9999-9999");
});*/


/********************draggin' itens****************************/
const columns = document.querySelectorAll(".draggable-column");

document.addEventListener("dragstart", (e) => {
  e.target.classList.add("dragging");
});

document.addEventListener("dragend", (e) => {
  e.target.classList.remove("dragging");
});

columns.forEach((item) => {
  item.addEventListener("dragover", (e) => {
    const dragging         = document.querySelector(".dragging");
    const parentController = dragging.closest(".draggable-column");

    if (item === parentController) {
      const applyAfter = getNewPosition(item, e.clientY);

      if (applyAfter) {
        /*console.log('item');
        console.log(item);
        console.log('parentController');
        console.log(parentController);
        console.log('applyAfter');
        console.log(applyAfter);*/

        item.insertBefore(dragging, applyAfter.nextSibling);
      } else {
        item.prepend(dragging);
      }
    }
  });
});


function getNewPosition(column, posY)
{
  const cards = column.querySelectorAll(":scope > .draggable-item:not(.dragging)");
  let result;

  for (let refer_card of cards)
  {
    const box = refer_card.getBoundingClientRect();
    const boxCenterY = box.y + box.height / 2;
    if (posY >= boxCenterY) result = refer_card;
  }

  return result;
}


/*
 * Verify required inputs if they're empty.
 */
$(document).on('click', '[data-send-without-reload] [type="submit"]', function (e)
{
  var requiredFields = $(this).closest('[data-send-without-reload]').find('[required]');
  var allFieldsValid = true;
  requiredFields.each(function () {

    if ($(this).val() === '')
    {
      allFieldsValid = false;
      $(this).addClass('is-invalid');
    }

    else $(this).removeClass('is-invalid');
  });

  if (!allFieldsValid) return;
});


/*
 * Send form without reload or any redirect.
 */
$(document).on('submit', '[data-send-without-reload]', function (e)
{
  e.preventDefault();

  // Form
  var form = $(this).closest('[data-send-without-reload]');
  var form_action = form.attr('action');
  var form_method = form.attr('method');
  var formData = new FormData(form[0]);

  var requiredFields = form.find('[required]');
  var allFieldsValid = true;
  requiredFields.each(function () {

    if ($(this).val() === '')
    {
      allFieldsValid = false;
      $(this).addClass('is-invalid');
    }

    else $(this).removeClass('is-invalid');
  });

  if (!allFieldsValid) return;

  var submit_button = $(this);
  var submit_button_spin = submit_button.find('.spinner-border-sm');
  submit_button.attr('disabled', true);
  submit_button_spin.show();

  $.ajax({
    url: form_action,
    type: form_method,
    data: formData,
    processData: false,
    contentType: false,
    success: function (response)
    {
      submit_button.removeAttr('disabled');
      submit_button_spin.hide();

      alert("YEAAH! Recebemos seu formulário e em breve alguém do time entrará em contato com você!")

      /*$('body').append(response.msg);
      var toast = $('.toast');
      toast.toast('show');

      toast.on('hidden.bs.toast', function () {
        toast.remove();
      });*/
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log(jqXHR);
      console.log(textStatus);
      console.log(errorThrown);
    }
  });
});


/*
 * JS for situation button.
 */
$(document).on('click', '[data-type]', function(event)
{
  var button = $(this);
  var id     = button.attr('id').split('situation-').join('');    // Take the ID
  var mode   = button.data('mode');                               // Take the mode
  var type   = button.data('type');                               // Take the type

  button.attr('disabled', '');    // Disable the button

  $.ajax({
    method: 'POST',
    url: pg + '/rest-api/switch-situation-button',
    data: {
      id: id,
      mode: mode,
      type: type,
    },
    success: function(response)
    {
      $('body').append(response.msg);
      var toast = $('.toast');
      toast.toast('show');

      toast.on('hidden.bs.toast', function () {
        toast.remove();
      });

      button.removeAttr("disabled");                              // Able the button
      button.replaceWith(response.button);                        // Replace the old with the new
    }
  });
});


/*
 * JS for regressive counters.
 */
var all_final_moments = document.querySelectorAll("[data-final-moment]");
if (all_final_moments != null)
{
  all_final_moments.forEach(function(current_moment)
  {
    var final_moment = current_moment.getAttribute('data-final-moment');
    var slipted_moment = final_moment.split(/[\s:-]+/);
    var final_hour = new Date(slipted_moment[0], slipted_moment[1] - 1, slipted_moment[2], slipted_moment[3], slipted_moment[4], slipted_moment[5] || 0);

    var interval = setInterval(function()
    {
      var sp_now = new Date(new Date().toLocaleString('en-US', {
        timeZone: 'America/Sao_Paulo'
      }));

      var diference = final_hour - sp_now;
      var viewer_years = current_moment.querySelector('[data-years]');
      var viewer_months = current_moment.querySelector('[data-months]');
      var viewer_days = current_moment.querySelector('[data-days]');
      var viewer_hours = current_moment.querySelector('[data-hours]');
      var viewer_minutes = current_moment.querySelector('[data-minutes]');
      var viewer_seconds = current_moment.querySelector('[data-seconds]');

      if (diference <= 0)
      {
        clearInterval(interval);
        viewer_years.textContent   = '00';
        viewer_months.textContent  = '00';
        viewer_days.textContent    = '00';
        viewer_hours.textContent   = '00';
        viewer_minutes.textContent = '00';
        viewer_seconds.textContent = '00';
        return;
      }

      var years   = Math.floor(diference / (1000 * 60 * 60 * 24 * 365.25));
      var months  = Math.floor((diference % (1000 * 60 * 60 * 24 * 365.25)) / (1000 * 60 * 60 * 24 * 30.44));
      var days    = Math.floor((diference % (1000 * 60 * 60 * 24 * 30.44)) / (1000 * 60 * 60 * 24));
      var hours   = Math.floor((diference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((diference % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((diference % (1000 * 60)) / 1000);

      (years !== 0) ? viewer_years.textContent = `${years}`.padStart(2, '0') : viewer_years.parentNode.style.display = 'none';
      (months !== 0) ? viewer_months.textContent = `${months}`.padStart(2, '0') : viewer_months.parentNode.style.display = 'none';
      (days !== 0) ? viewer_days.textContent = `${days}`.padStart(2, '0') : viewer_days.parentNode.style.display = 'none';
      viewer_hours.textContent = `${hours}`.padStart(2, '0');
      viewer_minutes.textContent = `${minutes}`.padStart(2, '0');
      viewer_seconds.textContent = `${seconds}`.padStart(2, '0');
    }, 500);
  });
}